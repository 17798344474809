import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	FeaturedClearing,
	FeaturedPostgraduate,
	FeaturedUndergraduate,
	Product,
	SourceTrackingTypes,
} from '@uc/web/shared/data-models';
import { PrimaryBlueBtnComponent, VisitWebsiteBtnComponent } from '@web/shared/ui';
import { ScrollIntoViewDirective } from '../directives/scroll-into-view.directive';

@Component({
	selector: 'uc-client-profile-sticky-lead-bar-mobile',
	standalone: true,
	templateUrl: './client-profile-sticky-lead-bar-mobile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VisitWebsiteBtnComponent, PrimaryBlueBtnComponent, ScrollIntoViewDirective],
})
export class ClientProfileStickyLeadBarMobileComponent {
	@Input({ required: true }) sourceType!: SourceTrackingTypes;
	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) source!: string;
	@Input({ required: true }) externalUrl!: string | undefined;
	@Input({ required: true }) isFeatured!:
		| FeaturedUndergraduate
		| FeaturedPostgraduate
		| FeaturedClearing;
	@Input() product!: Product;
	@Input() uniId!: number;
	@Input() prospectusExternalUrl!: string | undefined;
	@Input() eventExternalUrl!: string | undefined;
	@Input() ugSearchOrderPosition!: number | null;

	componentName = 'Sticky Lead Bar';
}
