<div class="flex justify-end gap-3">
	@if (isFeaturedCalls) {
		<uc-call-uni-btn
			customStyles="mb-0"
			[uniId]="uniId"
			[uniName]="uniName"
			buttonText="Call University"
			[phoneNumber]="phoneNumber"
			[source]="source"
			[sourceType]="sourceType"
			componentName="Sticky Lead Bar"
		/>
	}
	@if (isFeaturedRegisterInterest) {
		<uc-register-interest-btn
			customStyles="mb-0"
			[uniId]="uniId"
			[sourceType]="sourceType"
			componentName="Sticky Lead Bar"
		/>
	}
	@if (isFeaturedClearingVisitWebsite) {
		<uc-visit-website-btn
			[uniId]="uniId"
			[componentName]="componentName"
			[product]="product"
			[externalUrl]="externalUrl"
			[source]="source"
			[sourceType]="sourceType"
			[uniName]="uniName"
		/>
	}
</div>
