<div
	scrollIntoView
	class="z-50 w-full p-5 border-t bg-uc-blue-50 border-uc-blue-200 pb-7 sm:hidden sticky-lead-bar"
>
	<div class="w-full h-10" id="universityButtons">
		@if (isFeatured) {
			<uc-visit-website-btn
				customStyles="w-full"
				[externalUrl]="externalUrl"
				[product]="product"
				[componentName]="componentName"
				[uniId]="uniId"
				[source]="source"
				[sourceType]="sourceType"
				[ugSearchOrderPosition]="ugSearchOrderPosition"
				[uniName]="uniName"
			/>
		} @else {
			<uc-primary-blue-btn
				link="/universities"
				buttonText="View Recommended Universities"
				customStyles="w-full h-full"
			/>
		}
	</div>
</div>
