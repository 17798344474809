import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	Product,
	SourceTrackingTypes,
	FeaturedCalls,
	FeaturedRegisteredInterest,
	FeaturedClearingVisitWebsite,
} from '@uc/web/shared/data-models';
import {
	RegisterInterestBtnComponent,
	CallUniBtnComponent,
	VisitWebsiteBtnComponent,
} from '@web/shared/ui';

@Component({
	selector: 'uc-cta-clearing-buttons',
	standalone: true,
	templateUrl: './cta-clearing-buttons.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		RegisterInterestBtnComponent,
		CallUniBtnComponent,
		VisitWebsiteBtnComponent,
	],
})
export class CtaClearingButtonsComponent {
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) uniName = '';
	@Input({ required: true }) product!: Product;
	@Input({ required: true }) componentName!: string;
	@Input({ required: true }) externalUrl!: string;
	@Input({ required: true }) phoneNumber!: string;
	@Input({ required: true }) source!: string;
	@Input({ required: true }) sourceType: SourceTrackingTypes = '';
	@Input() isFeaturedCalls!: FeaturedCalls;
	@Input() isFeaturedRegisterInterest!: FeaturedRegisteredInterest;
	@Input() isFeaturedClearingVisitWebsite!: FeaturedClearingVisitWebsite;
}
