<div
	scrollIntoView
	class="z-50 hidden w-full border-t h-30 lg:inline sticky-lead-bar"
	[ngClass]="{
		'bg-uc-blue-50 border-uc-blue-200': !showClearing,
		'bg-white': showClearing,
	}"
>
	<div class="container">
		<div class="flex gap-20">
			<div class="w-full py-8">
				<h3
					class="text-xl font-bold leading-8"
					[ngClass]="{
						'text-uc-blue-900': !showClearing,
						'text-uc-green-700': showClearing,
					}"
				>
					{{ showClearing ? clearingText.title : nonClearingText.title }}
				</h3>
				<p class="leading-6 text-gray-600">
					{{
						showClearing
							? clearingText.description
							: nonClearingText.description
					}}
				</p>
			</div>
			<div class="self-center w-auto py-8">
				@if (!showClearing) {
					<uc-cta-buttons
						[uniId]="uniId"
						[product]="product"
						[externalUrl]="externalUrl"
						[prospectusExternalUrl]="prospectusExternalUrl"
						[componentName]="componentName"
						[externalEventsUrl]="externalEventsUrl"
						[source]="source"
						[sourceType]="sourceType"
						[privacyPolicyUrl]="privacyPolicyUrl"
						[courseName]="courseName"
						[isFeaturedUndergraduate]="clientFeatures.undergraduate"
						[isFeaturedPostgraduate]="clientFeatures.postgraduate"
						[ugSearchOrderPosition]="ugSearchOrderPosition"
						[uniName]="uniName"
					/>
				}
				@if (showClearing) {
					<uc-cta-clearing-buttons
						[uniId]="uniId"
						[uniName]="uniName"
						[product]="productEnum.Clearing"
						[externalUrl]="externalUrl"
						[isFeaturedCalls]="clientFeatures.calls"
						[isFeaturedRegisterInterest]="clientFeatures.registerInterest"
						[isFeaturedClearingVisitWebsite]="
							clientFeatures.clearingVisitWebsite
						"
						[phoneNumber]="phoneNumber"
						[componentName]="componentName"
						[source]="source"
						[sourceType]="sourceType"
					/>
				}
			</div>
		</div>
	</div>
</div>
